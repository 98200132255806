import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app-container">
      <header className="app-header">
        <h1>Welcome to Ryo Technologies!</h1>
      </header>
      <main className="app-body">
        <p>We make best in class Hashicorp Vault configuration solutions.</p>
      </main>
    </div>
  );
}

export default App;